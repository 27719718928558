// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---themes-gatsby-theme-wpgrapql-src-templates-portfolio-index-js": () => import("/Users/paulchapman/Primary/clients/2019/ideal-gatsby/themes/gatsby-theme-wpgrapql/src/templates/portfolio/index.js" /* webpackChunkName: "component---themes-gatsby-theme-wpgrapql-src-templates-portfolio-index-js" */),
  "component---themes-gatsby-theme-wpgrapql-src-pages-404-js": () => import("/Users/paulchapman/Primary/clients/2019/ideal-gatsby/themes/gatsby-theme-wpgrapql/src/pages/404.js" /* webpackChunkName: "component---themes-gatsby-theme-wpgrapql-src-pages-404-js" */),
  "component---themes-gatsby-theme-wpgrapql-src-pages-about-js": () => import("/Users/paulchapman/Primary/clients/2019/ideal-gatsby/themes/gatsby-theme-wpgrapql/src/pages/about.js" /* webpackChunkName: "component---themes-gatsby-theme-wpgrapql-src-pages-about-js" */),
  "component---themes-gatsby-theme-wpgrapql-src-pages-contact-js": () => import("/Users/paulchapman/Primary/clients/2019/ideal-gatsby/themes/gatsby-theme-wpgrapql/src/pages/contact.js" /* webpackChunkName: "component---themes-gatsby-theme-wpgrapql-src-pages-contact-js" */),
  "component---themes-gatsby-theme-wpgrapql-src-pages-home-with-loader-js": () => import("/Users/paulchapman/Primary/clients/2019/ideal-gatsby/themes/gatsby-theme-wpgrapql/src/pages/home-with-loader.js" /* webpackChunkName: "component---themes-gatsby-theme-wpgrapql-src-pages-home-with-loader-js" */),
  "component---themes-gatsby-theme-wpgrapql-src-pages-index-js": () => import("/Users/paulchapman/Primary/clients/2019/ideal-gatsby/themes/gatsby-theme-wpgrapql/src/pages/index.js" /* webpackChunkName: "component---themes-gatsby-theme-wpgrapql-src-pages-index-js" */),
  "component---themes-gatsby-theme-wpgrapql-src-pages-investors-js": () => import("/Users/paulchapman/Primary/clients/2019/ideal-gatsby/themes/gatsby-theme-wpgrapql/src/pages/investors.js" /* webpackChunkName: "component---themes-gatsby-theme-wpgrapql-src-pages-investors-js" */),
  "component---themes-gatsby-theme-wpgrapql-src-pages-news-js": () => import("/Users/paulchapman/Primary/clients/2019/ideal-gatsby/themes/gatsby-theme-wpgrapql/src/pages/news.js" /* webpackChunkName: "component---themes-gatsby-theme-wpgrapql-src-pages-news-js" */),
  "component---themes-gatsby-theme-wpgrapql-src-pages-old-news-js": () => import("/Users/paulchapman/Primary/clients/2019/ideal-gatsby/themes/gatsby-theme-wpgrapql/src/pages/old-news.js" /* webpackChunkName: "component---themes-gatsby-theme-wpgrapql-src-pages-old-news-js" */),
  "component---themes-gatsby-theme-wpgrapql-src-pages-playground-chart-animation-js": () => import("/Users/paulchapman/Primary/clients/2019/ideal-gatsby/themes/gatsby-theme-wpgrapql/src/pages/playground/chartAnimation.js" /* webpackChunkName: "component---themes-gatsby-theme-wpgrapql-src-pages-playground-chart-animation-js" */),
  "component---themes-gatsby-theme-wpgrapql-src-pages-playground-e-animation-js": () => import("/Users/paulchapman/Primary/clients/2019/ideal-gatsby/themes/gatsby-theme-wpgrapql/src/pages/playground/eAnimation.js" /* webpackChunkName: "component---themes-gatsby-theme-wpgrapql-src-pages-playground-e-animation-js" */),
  "component---themes-gatsby-theme-wpgrapql-src-pages-playground-logo-animation-js": () => import("/Users/paulchapman/Primary/clients/2019/ideal-gatsby/themes/gatsby-theme-wpgrapql/src/pages/playground/logoAnimation.js" /* webpackChunkName: "component---themes-gatsby-theme-wpgrapql-src-pages-playground-logo-animation-js" */),
  "component---themes-gatsby-theme-wpgrapql-src-pages-playground-scrolltest-js": () => import("/Users/paulchapman/Primary/clients/2019/ideal-gatsby/themes/gatsby-theme-wpgrapql/src/pages/playground/scrolltest.js" /* webpackChunkName: "component---themes-gatsby-theme-wpgrapql-src-pages-playground-scrolltest-js" */),
  "component---themes-gatsby-theme-wpgrapql-src-pages-portfolio-js": () => import("/Users/paulchapman/Primary/clients/2019/ideal-gatsby/themes/gatsby-theme-wpgrapql/src/pages/portfolio.js" /* webpackChunkName: "component---themes-gatsby-theme-wpgrapql-src-pages-portfolio-js" */),
  "component---themes-gatsby-theme-wpgrapql-src-pages-strategy-js": () => import("/Users/paulchapman/Primary/clients/2019/ideal-gatsby/themes/gatsby-theme-wpgrapql/src/pages/strategy.js" /* webpackChunkName: "component---themes-gatsby-theme-wpgrapql-src-pages-strategy-js" */)
}

